import React, { Component, Fragment } from "react"
import SearchBar from "./gamekeys/searchbar"
import ResultList from "./gamekeys/resultlist"
class GameSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
    }
    this.handleResultsChange = this.handleResultsChange.bind(this)
  }

  handleResultsChange(results) {
    console.log(results)
    if (results) {
      this.setState({
        results: results,
      })
    }
  }

  render() {
    const _this = this
    return (
      <Fragment>
        <SearchBar changer={_this.handleResultsChange} />
        {_this.state.results && _this.state.results.length > 0 && (
          <ResultList products={_this.state.results} />
        )}
      </Fragment>
    )
  }
}

export default GameSearch
