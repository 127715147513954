import React, { Component } from "react"

import axios from "axios"
import Form from "react-bootstrap/Form"

import API_URL from "../../resources/api"
import SearchFilter from "./searchfilter"

class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.getGames = this.getGames.bind(this)
  }

  resultFunc = this.props.changer

  state = {
    query: "",
    keyseller: [],
  }

  getGames() {
    const _this = this
    axios
      .post(`${API_URL}/gamekey/search/`, {
        searchstring: _this.state.query,
      })
      .then(res => {
        _this.resultFunc(res.data)
      })
  }

  handleInputChange() {
    const _this = this
    _this.setState(
      {
        query: _this.search.value,
      },
      () => {
        if (_this.state.query && _this.state.query.length > 1) {
          _this.getGames()
        }
      }
    )
  }

  render() {
    return (
      <Form>
        <Form.Group controlId="formGamekeySearch">
          <Form.Control
            size="lg"
            type="text"
            ref={input => (this.search = input)}
            placeholder="Name des Spiels eingeben ..."
            onChange={this.handleInputChange}
          />
          <SearchFilter keyseller={this.state.keyseller} />
        </Form.Group>
      </Form>
    )
  }
}

export default SearchBar
