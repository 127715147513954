import React, { Component } from "react"
import Form from "react-bootstrap/Form"
import { Collapse } from "react-collapse"
class SearchFilter extends Component {
  constructor(props) {
    super(props)
    console.log(props.keyseller)
  }

  render() {
    return (
      <div>
        <strong>Filter</strong>

        <Collapse isOpened={false}>
          {this.props.keyseller.map(item => (
            <Form.Check
              inline
              key={item.slug}
              id={item.slug}
              type="checkbox"
              label={item.title}
              defaultChecked="true"
            />
          ))}
        </Collapse>
      </div>
    )
  }
}

export default SearchFilter
