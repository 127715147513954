import React from "react"
import { Row, Col, Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GameSearch from "../components/gamesearch"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO
      title="Gamekey Suche"
      keywords={[`gamekeys`, `steam`, `suche nach gamekeys`]}
      description="Dank Gamekeycheck findest du immer die günstigsten Keys für dein Lieblingsspiel. Eine simple Suche ohne jegliche Schnick-Schnack. Von Gamern für Gamer."
    />
    <Container className="text-center">
      <Row>
        <Col>
          <GameSearch />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
